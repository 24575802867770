import { observable } from "mobx";
import JSONData from "../data/hotels.json";

class SearchModel {
  @observable errorPlaces = false;
  @observable errorHotels = false;
  @observable errorDuration = false;
  @observable firstPlace = null;
  @observable features = null;
  @observable query = null;
  @observable searchQuery = {
    value: "",
    type: "place",
    booking_id: "",
  };
  @observable places = [];
  @observable hotels = [];
  @observable searchResultsHotels = [];
  @observable focusedInput = null;
  @observable startDate = null;
  @observable endDate = null;
  @observable couponCode = "";
  hotelList = JSONData;
}

const SearchStore = new SearchModel();
export default SearchStore;
