import React from "react";

const Context = React.createContext();

export const Provider = ({ children, value }) => {
  const { data, pageContext: context } = value;

  return (
    <Context.Provider value={{ data, context }}>{children}</Context.Provider>
  );
};

export default Context;
