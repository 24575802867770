// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-404-js": () => import("./../../../src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-templates-account-js": () => import("./../../../src/templates/account.js" /* webpackChunkName: "component---src-templates-account-js" */),
  "component---src-templates-careers-js": () => import("./../../../src/templates/careers.js" /* webpackChunkName: "component---src-templates-careers-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-faq-js": () => import("./../../../src/templates/faq.js" /* webpackChunkName: "component---src-templates-faq-js" */),
  "component---src-templates-groups-js": () => import("./../../../src/templates/groups.js" /* webpackChunkName: "component---src-templates-groups-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-hotel-js": () => import("./../../../src/templates/hotel.js" /* webpackChunkName: "component---src-templates-hotel-js" */),
  "component---src-templates-hotels-js": () => import("./../../../src/templates/hotels.js" /* webpackChunkName: "component---src-templates-hotels-js" */),
  "component---src-templates-offer-js": () => import("./../../../src/templates/offer.js" /* webpackChunkName: "component---src-templates-offer-js" */),
  "component---src-templates-offers-js": () => import("./../../../src/templates/offers.js" /* webpackChunkName: "component---src-templates-offers-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-region-js": () => import("./../../../src/templates/region.js" /* webpackChunkName: "component---src-templates-region-js" */),
  "component---src-templates-regions-js": () => import("./../../../src/templates/regions.js" /* webpackChunkName: "component---src-templates-regions-js" */),
  "component---src-templates-results-js": () => import("./../../../src/templates/results.js" /* webpackChunkName: "component---src-templates-results-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */),
  "component---src-templates-services-js": () => import("./../../../src/templates/services.js" /* webpackChunkName: "component---src-templates-services-js" */),
  "component---src-templates-spirit-js": () => import("./../../../src/templates/spirit.js" /* webpackChunkName: "component---src-templates-spirit-js" */),
  "component---src-templates-spirits-js": () => import("./../../../src/templates/spirits.js" /* webpackChunkName: "component---src-templates-spirits-js" */)
}

